/** Wrapper for messaging libraries.
*/
// import messagingPubNub from './messagingPubNub'
import messagingPusher from './messagingPusher'
// import messagingAgora from './messagingAgora'

// import config from '/config'

class Messaging {
    constructor(app) {
        this.AGORA = 'agora'
        this.PUBNUB = 'pubnub'
        this.PUSHER = 'pusher'

        this.app = app
        this.library = null

        this.defaultOptions = {}
        this.mode = ''

        this.userId = ''

        this.initialized = false
        this.startedInitialization = false // Store for using in caller modules.

        this.eventHandlers = {}

        this.channelEventNames = {}

        this.presenceChannel = ''
        this.publicChannel = ''
        // this.typingChannel = ''
        this.privateChannel = ''
        this.clientOnlyEvents = []
    }

    init(currentUser) {
        console.log("initialize messaging ..")
        if (this.startedInitialization) {
            return
        }
        this.startedInitialization = true

        this.disconnect()

        this.presenceChannel = 'user_presence'
        this.publicChannel = 'public_users'
        // this.typingChannel = 'typing_channel'
        this.privateChannel = 'App.User.' + currentUser.id

        this.channelEventNames = {}
        this.channelEventNames[this.privateChannel] = [
            'NewInboxMessage',
            'MissedInboxMessageEvent',
            'NewMessageEvent',
            'ChatMessageRetry',
            'ProfileChangedEvent',
            'NotificationEvent',
            'NewInboxChargeEvent',
            'UserInboxChanged',
            'ChargeStatusChanged',
            'UserStatusChanged',
            'InboxMessagesReadEvent',
            'InboxMessagesDeletedEvent',
            'InvationUsedEvent',
            'NewChatSessionEvent',
            'ChatSessionClosedEvent',
            'ChatSessionTimeoutReject',
            'ChatStateChanged',
            'ChatPaidMinutesChanged',
            'ChatSessionTimerStartedEvent',
            'ChatSessionPausedEvent',
            'ChatSessionUnPausedEvent',
            'AccountActivatedEvent',
            'UserBlockedYouEvent',
            'UserUnBlockedYouEvent',
            'UserBlockedEvent',
            'AmountChangedEvent',
            'FreeMinutesUsedEvent',
            'FreeMinutesReceivedEvent',
            'TypingInboxEvent',
            'TypingChatEvent',
        ]
        this.channelEventNames[this.presenceChannel] = [
            'here',
            'joining',
            'leaving',
        ]
        // this.channelEventNames['data_refresh'] = [
        // ]
        this.channelEventNames[this.publicChannel] = [
            'ProfileChangedEvent',
            'HardRefreshPage',
            'UserPresenceStatusChanged',
        ]
        // this.channelEventNames[this.typingChannel] = [
        //     'typing',
        // ]
        // this.clientOnlyEvents = [
        //     'typing',
        // ]

        const userId = (currentUser?.id ?? 'guest')

        // Receiving messaging tokens and settings.
        let _this = this
        this.app.$api.get('/auth/refresh')
            .then((res) => {

                _this.mode = res.data.mode
                _this.defaultOptions = res.data.options

                if (!_this.userId || _this.userId !== userId) {
                    _this.userId = userId
                    let libraryClass = null
                    switch (_this.mode) {
                        case _this.AGORA:
                            libraryClass = messagingAgora
                            break
                        case _this.PUSHER:
                            libraryClass = messagingPusher
                            break
                        case _this.PUBNUB:
                            libraryClass = messagingPubNub
                            break
                        default:
                            throw `Mode ${_this.mode} is not supported`
                    }
                    _this.library = new libraryClass(
                        _this.app,
                        _this.defaultOptions,
                        _this.userId,
                        _this.eventHandlers,
                        _this.channelEventNames,
                        _this.presenceChannel,
                        _this.publicChannel,
                        // _this.typingChannel,
                        _this.privateChannel,
                        _this.clientOnlyEvents
                    )
                    _this.library.init()
                }
            })
            .catch(async (res) => {
                console.error('[get messaging token]', res)

                // If unable to update user info this means the user token is obsolete.
                if (res.status == 422) {
                    await _this.app.$auth.logout()
                }
            })

        this.initialized = true
        this.startedInitialization = false
    }

    subscribe(eventHandlers) {
        for (let h in eventHandlers) {
            if (!this.eventHandlers[h]) {
                this.eventHandlers[h] = []
            }
            this.eventHandlers[h][this.eventHandlers[h].length] = eventHandlers[h]
        }
    }

    disconnect() {
        if (this.library) {
            this.library.disconnect()
            this.library = null
        }

        this.startedInitialization = false
    }

    sendMissedInboxMessage(message, senderId, recipientId, callback) {
        this.#sendMessage({
            storeInHistory: false,
            message: {
                event: 'MissedInboxMessageEvent',
                message: message,
                receiver_id: recipientId,
                sender_id: senderId,
            },
            // channel: 'user_presence',
            channel: 'App.User.' + recipientId,
        }, callback)
    }

    // markChatRead(chatId, userId, callback) {
    //     this.#sendMessage({
    //     	message: {
    //     		read: true,
    //     		chat_id: chatId,
    //     	},
    //         channel: 'temp_' + userId,
    // 	}, callback)
    // }

    // sendTypingInboxMessage(userId, recipientId, inboxId, isTyping, callback) {
    //     this.#sendMessage({
    //         storeInHistory: false, //override default storage options
    //         message: {
    //             // event: 'UserTypingEvent',
    //             event: 'typing',
    //             user: userId,
    //             sender_id: userId,
    //             receiver_id: recipientId,
    //             type: 'inbox',
    //             inbox_id: inboxId,
    //             is_typing: isTyping
    //         },
    //         // channel: 'App.User.' + userId,
    //         channel: 'App.User.' + recipientId,
    //         // channel: this.typingChannel,
    // 	}, callback)
    // }
    //
    // sendTypingChatMessage(userId, recipientId, chatId, isTyping, callback) {
    //     this.#sendMessage({
    //         storeInHistory: false, //override default storage options
    //         message: {
    //             // event: 'UserTypingEvent',
    //             event: 'typing',
    //             user: userId,
    //             sender_id: userId,
    //             receiver_id: recipientId,
    //             type: 'chat',
    //             chat_id: chatId,
    //             is_typing: isTyping
    //         },
    //         channel: 'App.User.' + recipientId,
    //         // channel: this.typingChannel,
    // 	}, callback)
    // }

    sendTypingInboxMessage(userId, recipientId, inboxId, isTyping, callback) {
        this.app.$api.post('/api/user/typing-inbox', {
            inbox_id: inboxId,
            receiver_id: recipientId,
            is_typing: isTyping,
        })
            .then((res) => {
                if (callback) {
                    callback(res)
                }
            })
    }

    sendTypingChatMessage(userId, recipientId, chatId, isTyping, callback) {
        this.app.$api.post('/api/user/typing-chat', {
            chat_id: chatId,
            receiver_id: recipientId,
            is_typing: isTyping,
        })
            .then((res) => {
                if (callback) {
                    callback(res)
                }
            })
    }

    // // sendMissedChatMessage(message, chatId, senderId, callback) {
    // sendMissedChatMessage(message, chatId, recipientId, callback) {
    //     this.#sendMessage({
    //         storeInHistory: false,
    // 		message: {
    // 			event: 'MissedMessageEvent',
    // 			message: msg
    // 		},
    // 		// channel: 'user_presence',
    // 		channel: 'App.User.' + recipientId,
    // 	}, callback)
    // }

    #sendMessage(options, callback) {
        console.log('send message', options)
        this.library.sendMessage(options, callback)
    }
}

export default {
    install: function (app, options) {
        const messaging = new Messaging(app)
        app.config.globalProperties.messaging = messaging
        app.config.globalProperties.$messaging = messaging
        app.$messaging = messaging
    }
}
